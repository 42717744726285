/* Ajusta la altura de los eventos */
.fc-timeline-event,
.fc-event {
  height: 34px !important;
  line-height: 34px !important; /* Alinea el texto verticalmente */
}

/* Opcional: Ajusta la altura del contenido del evento */
.fc-timeline-event .fc-event-title,
.fc-event .fc-event-title {
  height: 34px;
  line-height: 34px; /* Alinea el texto verticalmente */
}

/* Cambiar el color de los encabezados de los días */
.fc-timeline-slot-cushion {
  color: #333; /* Cambia a tu color preferido */
}

/* Quitar el subrayado al pasar el mouse */
.fc-timeline-slot-cushion:hover {
  text-decoration: none;
}

/* Custom styles for Tippy.js tooltips */
.tippy-box {
  background-color: rgb(255, 255, 255) !important;
}

.tippy-content {
  padding: 0 !important; /* Elimina el padding */
}

.fc .fc-scrollgrid {
  border: 0;
}

.fc-timeline-event:not(.fc-event-end)::after,
.fc-timeline-event:not(.fc-event-start)::before {
  margin: 0px 8px;
}

.fc-datagrid-body {
  .fc-datagrid-cell-frame {
    display: flex;
    align-items: center;
  }
}

.fc-theme-standard td,
.fc-theme-standard th {
  border-bottom: 1px solid var(--fc-border-color) !important;
}

.fc td,
.fc th {
  padding: 0px !important;
  vertical-align: middle !important;
}

.fc-day {
  background-color: rgba(247, 247, 247, 1);
}

.fc-day:nth-child(even) {
  background-color: white;
}
