.btn-arrows-schedule {
  background-color: #e9e6e6;
  border-color: #cccccc;
  color: #5e6163;
}
.btn-arrows-schedule:hover {
  background-color: #b6b2b2;
  border-color: #a5a5a5;
}
.btn-arrows-schedule:focus {
  background-color: #b6b2b2;
  border-color: #a5a5a5;
  box-shadow: 0 0 0 0.2rem rgba(210, 212, 212, 0.5);
}
.btn-arrow-group {
  display: flex;
  justify-content: right;
  padding: 0.5rem;
}
#btn-schedule-previous {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
#btn-schedule-next {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
