//sass-migrator division **/*.scss

body {
  background-size: cover;
  background-position-y: 0;
}

.min-width-992px {
  min-width: 993px;
}

.bg-lightblue {
  background-color: #f4f7fc;
}

.wrapper {
  &:before {
    display: none;
  }
}

.wrapper.overflow-x-visible {
  &:before {
    min-width: 992px;
  }
}

.brand-img {
  border-radius: 0.5rem;
}

.user-name {
  width: 80px;
  height: 80px;
  font-size: 1rem;
  background-color: #fcb8121c;
}

.sidebar-brand,
.sidebar-brand:hover {
  margin: 0 auto;
  max-width: 200px;
  background: transparent;
}

.btn-white {
  background-color: white;
}

.text-custom-red {
  color: #dc3545;
}

.text-underline {
  text-decoration: underline !important;
}

.max-width-20 {
  max-width: 20px;
}

.min-width-50 {
  min-width: 50px;
}

.min-width-100 {
  min-width: 100px;
}

.max-width-150 {
  max-width: 150px;
}

.min-width-300 {
  min-width: 300px;
}

.min-width-250 {
  min-width: 250px;
}

.min-width-lg {
  min-width: 992px;
}

.box-shadow-none {
  box-shadow: none !important;
}

.min-vh-20 {
  min-height: 20vh !important;
}

.min-height-50 {
  min-height: 50%;
}

.height-100 {
  height: 100%;
}

.min-height-100-px {
  min-height: 100px;
}

.bg-transparent {
  background: transparent !important;
}

.bg-lighter {
  background: #eeeeee4f !important;
}

.bg-lighter-tabs {
  background: #f4f7fc !important;
}

.font-size-75 {
  font-size: 0.75rem;
}

.font-size-85 {
  font-size: 0.85rem;
}

.font-size-95 {
  font-size: 0.95rem;
}

.font-size-65 {
  font-size: 0.65rem;
}

.font-size-1 {
  font-size: 1rem;
}

.font-size-1-1 {
  font-size: 1.1rem;
}

.font-size-1-2 {
  font-size: 1.2rem;
}

.align-content-evenly {
  align-content: space-evenly;
}

.word-break-all {
  word-break: break-all;
}

.max-width-200 {
  max-width: 200px;
}

.max-width-250 {
  max-width: 150px;
}

.max-width-100 {
  max-width: 100px;
}

.width-170 {
  width: 170px;
}

.width-100-px {
  width: 100px;
}

.width-100 {
  width: 100%;
}

.z-index-2 {
  z-index: 2;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-hidden {
  overflow-x: visible !important;
}

.footer {
  background: #ffffffad;
}

.absolute-right {
  position: absolute;
  right: 0;
}

.bg-light {
  background-color: #e9ecef !important;
}

.work-order-details-nav {
  .nav-link:not(.active) {
    border: none !important;
  }

  .nav-link.active {
    background-color: white;
    border-bottom: none;
    font-weight: 500;
  }
}

.conflict i {
  color: #dc3545 !important;
}

.custom-control-label {
  padding-top: 2px;
}

.overflow-x-auto {
  overflow-x: auto;
}

.font-500 {
  font-weight: 500;
}

.font-300 {
  font-weight: 300;
}

.bg-lightblue {
  background-color: rgb(230, 240, 255);
}

.date-range-picker {
  font-size: inherit;
}

.date-range-picker
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(
    .DayPicker-Day--end
  ):not(.DayPicker-Day--outside) {
  background-color: #4a90e21f !important;
  color: #4a90e2;
}

.date-range-picker .DayPicker-Day {
  border-radius: 0 !important;
}

.border-right-radius-none {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.border-left-radius-none {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.date-range-picker .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.date-range-picker .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.calendar-container {
  width: 100%;
  padding: 20px 20px 0 20px;
  border: 1px solid #d4e0f3;
  background: #f6faff;
  border-radius: 5px;
}

.box-shadow-none {
  box-shadow: none;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.text-xl {
  font-size: 2.875rem !important;
}

.progress-container {
  margin: 0 auto;
}

.progress-line {
  height: 1px;
  background: #dee2e6;
}

.status-container {
  z-index: 1;
  width: 100%;
  margin-top: -10px;
}

.outline-none,
.outline-none:focus {
  outline: none !important;
}

.border-radius-0 {
  border-radius: 0;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.ql-container.ql-snow {
  min-height: 100px;
}

.ql-toolbar {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid #dee2e6 !important;
  font-family: inherit;
}

.ql-container {
  font-family: inherit;
  border: none !important;
}

.expandable-task {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  -webkit-clip-path: none !important;
  clip-path: none !important;
}

.no-task {
  display: none !important;
}

.b-container .b-has-label label {
  color: #333 !important;
  margin: 0;
}

.b-label {
  text-transform: none !important;
}

.demo-header {
  display: none;
}

.cursor-default {
  cursor: default !important;
}

.report-tabs {
  overflow-x: auto;
  overflow-y: hidden;
  text-align: center;
  align-items: center;
}

.schedule-timeline svg g text {
  font-weight: 400;
  font-family: "Rubik";
}

.search-input.input-group-text {
  border-radius: 0.2rem 100% 90% 0.2rem !important;
}

.custom-rounded-button {
  padding: 8px;
}

.square-32 {
  width: 32px;
  height: 32px;
}

.height-40p {
  height: 40px;
}

.text-link {
  color: #3b7ddd;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.upper-close {
  position: absolute;
  right: -8px;
  top: -12px;
  z-index: 999;
}
