.date-picker .rdp-day {
  color: #8b9898;
  font-size: 0.875em;
}

.my-today {
  font-weight: 400;
}

.rdp-caption_label {
  font-size: 100% !important;
  z-index: 0 !important;
}

.rdp-months {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.my-selected:not(.my-range_start):not(.my-range_end) {
  background-color: #4a90e21f !important;
  color: #4a90e2;
}

.rdp-day {
  border-radius: 0 !important;
}

.my-range_start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.my-range_end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.calendar-container {
  background: #f6faff;
}
